import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Select,
  Text,
  TextArea,
  TextInput,
} from "grommet"
import { MailOption } from "grommet-icons"
import axios from "axios"
import PageLayout from "../../components/Layouts/PageLayout"

const ENQUIRY_TYPES = [
  { value: "REFUND", label: "Refunds" },
  { value: "PARTNERSHIPS", label: "Partnerships" },
  { value: "GENERAL", label: "General" },
]

const ContactUs = ({
  data: {
    strapiContactUs: {
      title,
      sub_heading,
      endpoint,
      body_placeholder,
      email_placeholder,
      request_placeholder,
      submit_button_label,
      generic_error_message,
      success_header,
      success_sub_heading,
    },
  },
}) => {
  const [value, setValue] = React.useState({})
  const [submitted, setSubmitted] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  return (
    <PageLayout>
      <PageLayout.Heading heading={title} sub_heading={sub_heading} />
      <PageLayout.Content
        flex="grow"
        width="medium"
        margin={{ vertical: "large" }}
        pad="medium"
      >
        {submitted && (
          <Box
            animation={{
              type: "fadeIn",
              delay: 0,
              duration: 2000,
              size: "xsmall",
            }}
            border={{ color: "brand", size: "xsmall" }}
            round="medium"
            flex="grow"
            align="center"
            justify="center"
            fill
          >
            <Heading level={3} textAlign="center" margin="xsmall">
              {success_header}
            </Heading>
            <Text>{success_sub_heading}</Text>
          </Box>
        )}
        {!submitted && (
          <Form
            align="center"
            value={value}
            onChange={nextValue => setValue(nextValue)}
            onReset={() => setValue({})}
            onSubmit={async ({ value }) => {
              setLoading(true)
              try {
                await axios.post(endpoint, value)
                setSubmitted(true)
              } catch (e) {
                setError(true)
              }
              setLoading(false)
            }}
          >
            <FormField name="email" htmlFor="email-id" disabled={loading}>
              <TextInput
                required
                icon={<MailOption />}
                id="email-id"
                name="email"
                type="email"
                size="small"
                placeholder={email_placeholder}
              />
            </FormField>
            <FormField
              required
              name="enquiry_type"
              htmlFor="enquiry-type-id"
              disabled={loading}
            >
              <Select
                placeholder={request_placeholder}
                size="small"
                id="enquiry-type-id"
                name="enquiry_type"
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                multiple={false}
                options={ENQUIRY_TYPES}
              />
            </FormField>
            <FormField
              required
              name="body"
              htmlFor="body-id"
              disabled={loading}
            >
              <TextArea
                id="body-id"
                name="body"
                size="small"
                resize={false}
                placeholder={body_placeholder}
              />
            </FormField>
            {error && (
              <Text size="small" color="status-error" textAlign="center">
                {generic_error_message}
              </Text>
            )}
            <Box direction="row" gap="medium" justify="center" pad="small">
              <Button
                type="submit"
                primary
                label={submit_button_label}
                disabled={loading}
              />
            </Box>
          </Form>
        )}
      </PageLayout.Content>
    </PageLayout>
  )
}

ContactUs.propTypes = {}

export default ContactUs

export const pageQuery = graphql`
  query($locale: String!) {
    strapiContactUs(locale: { eq: $locale }) {
      id
      title
      sub_heading
      endpoint
      body_placeholder
      email_placeholder
      request_placeholder
      submit_button_label
      generic_error_message
      success_header
      success_sub_heading
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
